<template>
    <div>

        <header class="bg-gray-50 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
            <div class="flex flex-wrap items-center justify-between mx-8 my-4">
                <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
                    My companies
                </h2>
                <div class="block pb-1 mt-2">
                    <v-btn color="secondary rounded-lg" @click="addNewCompany">
                        <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                        <span>New Company</span>
                    </v-btn>
                </div>
            </div>
        </header>
        <section class="flex flex-col justify-center mx-auto max-w-6xl">
            <div class="flex flex-wrap" v-if="isCompanyLoaded">
                <div class="w-full justify-start mx-auto mb-8 sm:w-1/2 px-4 lg:w-1/4 flex flex-col"
                    v-for="(item, index) in listenterprise" :key="index">
                    <div class="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-10 bg-white shadow-xl rounded-lg text-gray-900">
                        <div class="h-24 overflow-hidden">
                            <img class="h-24 object-cover object-top w-full" :src="item.isBannerDefined? item.banner: require('@/assets/images/bgs/person-docs.png')" cover alt="logo-user">
                        </div>
                        <div
                            class="mx-auto w-24 h-24 relative -mt-12 border-4 border-white rounded-full overflow-hidden">
                            <img alt='logo' class="object-cover object-center h-24"  :src="item.isLogoDefined? item.logo: require('@/assets/images/avatars/profil.jpg')"  cover>
                        </div>
                        <div class="text-center mt-2">
                            <h2 class="font-semibold">
                                {{ item.name }}
                            </h2>
                            <p class="text-gray-500 px-2 h-12 overflow-hidden" v-if="item.type">{{item.type.designation}}</p>
                        </div>
                        <ul class="py-4 mt-2 text-gray-700 flex items-center justify-around">
                            <li class="flex flex-col items-center justify-around">
                                <div class="w-4 fill-current text-blue-900" >
                                {{ item.nb_employee? item.nb_employee: 0 }}
                                </div>
                                <div>Employees</div>
                            </li>
                            <li class="flex flex-col items-center justify-between" v-if="item.country">
                                <v-img v-if="item.country.iso"
                                    :src="require('@/assets/images/flags/' + item.country.iso + '.svg')"
                                    :lazy-src="'@/assets/images/flags/' + item.country.iso + '.svg'" aspect-ratio="1"
                                    width="1.6em" max-width="1.6em" height="1em"
                                    class="text-center subsecondary lighten-2 mr-2">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-skeleton-loader v-bind="attrs" type="image, card-avatar, article"
                                                height="100%" width="100%"
                                                class="mx-auto rounded-pill"></v-skeleton-loader>
                                        </v-row>
                                    </template>
                                </v-img>
                                <div>{{ item.country.name ? item.country.iso : '' }}</div>
                            </li>
                        </ul>
                        <div class="p-4 border-t mx-8 mt-2">
                            <button class="w-1/1 block mx-auto secondary hover:shadow-lg text-white px-6 py-2"
                                @click="showCompanyDasboard(item, '')">
                                <!-- <v-icon small @click.color="secondary">
                                    {{ icons.mdiEye }}
                                </v-icon> -->
                                View
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap mt-10" v-else>
                <v-skeleton-loader v-for="i in 6" :key="i" class="w-auto mx-auto mb-8 sm:w-1/2 px-4 lg:w-1/3"
                    max-width="100%" type="card-avatar, article">
                </v-skeleton-loader>
            </div>
        </section>
        <!-- <v-data-table :headers="headers" :items="listenterprise" :search="search" :loading="isCompanyLoaded" -->
        <!-- <v-data-table :headers="headers" :items="listenterprise" :search="search"
            loading-text="Loading your companies... Please wait" sort-by="object" class="elevation-1 row-pointer my-4"
            style="cursor: pointer" @click:row="(item) => showCompanyDasboard(item, '')" flat v-if="isCompanyLoaded">
            <template v-slot:item.company="{ item }">
                <v-col class="d-flex flex-row align-center">
                    <v-avatar size="50" class="mr-3">
                        <v-img src="@/assets/images/avatars/profil.svg" cover></v-img>
                    </v-avatar>
                    <h4 class="text-capitalize">{{ item.name }}</h4>
                </v-col>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon class="subsecondary" @click="showCompanyDasboard(item, '')">
                    <v-icon small @click.color="secondary">
                        {{ icons.mdiEye }}
                    </v-icon>
                </v-btn> -->
        <!-- <v-menu bottom origin="center center" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="subsecondary" v-bind="attrs" v-on="on">
                            <v-icon small @click.color="secondary">
                                {{ icons.mdiDotsHorizontal }}
                            </v-icon>
                        </v-btn>
                    </template>

<v-card>
    <v-card-text class="pa-2 d-flex flex-column">
        <v-btn text small block @click="showCompanyDasboard(item, '')">Open</v-btn>
        <v-btn text small block @click="showCompanyDasboard(item, 'edit')">settings</v-btn>
    </v-card-text>
</v-card>
</v-menu> -->
        <!-- </template>
        </v-data-table> -->
        <!-- <div class="flex flex-column ma-4">
            <v-skeleton-loader v-for="i in 6" :key="i" class="w-full mb-8" max-width="100%" type="list-item-two-line">
            </v-skeleton-loader>
        </div> -->
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'
var md5 = require("md5")

export default {
    props: {
        showhead: {
            type: Boolean,
            default: true
        }
    },
    components: { CardHeader },
    data() {
        return {
            topheaders: {
                titlepage: 'MY COMPANIES',
                icon: null,
            },
            loading: false,
            isCompanyLoaded: false,
            listenterprise: [],

            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    methods: {
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },
        addNewCompany() {
            this.$router.push({ name: 'user.create.company' })
        },
        showCompanyDasboard(item, op) {
            const companie = this.listenterprise.find(element => element.id == item.id);
            this.$store.commit("setCompany", {
                id: companie.id,
                about: companie.about,
                logo: companie.logo,
                banner: companie.banner,
                name: companie.name,
                // activity_sector: companie.activity_sector,
                email: companie.email,
                // phonenumber: companie.phonenumber,
                // website: companie.website,
                // form: companie.enterprise_form,
                type: companie.type,
            });
            if (op === 'edit') {
                this.$router.push({ name: 'company.account', params: { company: companie.name } })
            }
            else {
                this.$router.push({ name: 'company.home', params: { company: companie.name } })
            }
            // this.$router.push({ name: 'company', params: { ids: this.listenterprise.indexOf(item), id: md5(this.listenterprise.indexOf(item)) } })
        },
        deleteItem(item) {
            this.editedIndex = this.listenterprise.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.listenterprise.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.listenterprise[this.editedIndex], this.editedItem)
            } else {
                this.listenterprise.push(this.editedItem)
            }
            this.close()
        },
    },
    mounted() {
        this.isCompanyLoaded = false
        Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.listenterprise.push(
                            {
                                id: value.id,
                                name: value.name,
                                employee: value.nb_employee,
                                country: value.country,
                                about: value.about,
                                email: value.email,
                                type: value.type,
                                logo: this.urlimg + 'enterprise/logo/' + value.id + '/' + value.logo,
                                banner: this.urlimg + 'enterprise/banner/' + value.id + '/' + value.banner,
                                isLogoDefined: value.logo ? true : false,
                                isBannerDefined: value.logo ? true : false,
                            })

                    });
                    this.isCompanyLoaded = true
                }
                else {
                    this.isCompanyLoaded = false
                }
            })
            .catch((err) => {
                alert('Server error')
                this.isCompanyLoaded = false
            });

    },
    setup(props) {
        // button data-table action
        const btnSingle = ref(false)
        const btnRow = ref(false)
        const title = props.title
        const icons = { mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus }
        let search = ref('')
        const headers = [
            {
                text: 'COMPANY',
                value: 'company',
                align: 'start',
                sortable: true,
            },
            {
                text: 'CATEGORY',
                value: 'type',
            },
            { text: 'WEBSITE', value: 'website' }, ,
            {
                text: 'CREATED AT',
                value: 'created_at',
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        const urlimg = Drequest.__envimg
        return {
            // button data-table action
            btnRow, btnSingle,
            title,
            icons,
            search,
            headers,
            urlimg
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>