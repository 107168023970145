<template>
    <div class="flex justify-between gap-x-6 pt-4 pb-2" v-if="join" @click="viewUserInfos()">
        <div class="flex min-w-0 gap-x-4 my-auto">
            <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                :src="join.isLogo ? urlimg + 'professional/logo/' + join.user.id + '/' + join.user.logo : require('@/assets/images/avatars/profil.jpg')"
                alt="">
            <div class="min-w-0 flex-auto">
                <div class="text-sm font-semibold leading-5 text-gray-900">{{ join.user.firstname }}</div>
                <div class="mt-1 truncate text-xs leading-6 text-gray-500">
                    {{ join.user.profession + (join.user.namecompany ? ' (' + join.user.namecompany + ')' :
                        '') }}</div>
                <p class="mt-1 flex w-auto text-xs leading-6">
                    <v-img v-if="join.user.country.iso"
                        :src="require('@/assets/images/flags/' + join.user.country.iso + '.svg')"
                        :lazy-src="'@/assets/images/flags/' + join.user.country.iso + '.svg'" aspect-ratio="1"
                        width="1.4em" max-width="1.4em" height="0.8em"
                        class="text-center subsecondary lighten-2 mr-2 my-1">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-skeleton-loader v-bind="attrs" type="image, card-avatar, article" height="100%"
                                    width="100%" class="mx-auto rounded-pill"></v-skeleton-loader>
                            </v-row>
                        </template>
                    </v-img> {{ join.user.country.name }}
                </p>
            </div>
        </div>
        <div class="hidden shrink-0 text-sm sm:flex sm:flex-col sm:items-end" v-if="stab === 2">
            <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p class="flex text-sm leading-6 rounded-pill px-4">
                    <span class="text-md subsecondary rounded-pill  px-2" v-if="join.status === 1">
                        <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon><span
                            class="secondary--text text-caption">awaiting</span>
                    </span>
                    <span class="text-md lightError rounded-pill px-2" v-if="join.status === 0">
                        <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
                        <span class="error--text text-caption">Rejected</span>
                    </span>
                </p>
                <p class="mt-1 text-xs leading-5">
                    <v-btn x-small v-if="join.status === '1'" color="error" width="auto"
                        class="px-4 w-full lightError mt-3 ml-auto" outlined @click="cancelInvitation(join)"
                        :loading="onCancel === true ? true : false">
                        Cancel
                    </v-btn>
                </p>
            </div>
        </div>
        <div class="hidden shrink-0 text-sm sm:flex sm:flex-col sm:items-end" v-if="stab === 3">
            <v-btn small color="secondary" class="px-4 text-sm w-full subsecondary rounded-lg" outlined
                @click="acceptInvitation(join)" :loading="onConf === true ? true : false">
                Accept
            </v-btn>
            <v-btn small color="error" class="px-4 text-sm w-full lightError mt-1 rounded-lg" outlined
                @click="deleteInvitation(join)" :loading="onDel === true ? true : false">
                Reject
            </v-btn>
        </div>
    </div>
    <!-- <v-card class="mx-0 my-3 fill-height" height="auto" v-if="join !== null" flat>
        <v-list-item three-line> -->
    <!-- <v-list-item-avatar tile size="80">
                <v-img src="@/assets/images/avatars/profil.svg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <router-link :to="{
                        name: 'user.views', params: {
                            f_id: o_md5(join.user.id), ids:
                                join.user.id
                        }
                    }">
                        {{ join.isOwner === true ? join.user.firstname : join.recipient.firstname }}</router-link>
                </v-list-item-title>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 text-caption">{{ join.isOwner === true ? join.user.email :
                        join.recipient.email
                        }}</span>
                </div>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiSortCalendarAscending }}</v-icon>
                    <span class="my-auto ml-1 mb-1 text-caption">{{ join.sended_at }}</span>
                </div>
                
            </v-list-item-content> -->

    <!-- <v-list-item-action>
                <div v-if="stab === 3">
                    <v-btn small color="secondary" class="px-4 w-full subsecondary" outlined
                        @click="acceptInvitation(join)" :loading="onConf === true ? true : false">
                        Accept
                    </v-btn>
                    <v-btn small color="error" class="px-4 w-full lightError mt-3" outlined
                        @click="deleteInvitation(join)" :loading="onDel === true ? true : false">
                        Reject
                    </v-btn>
                </div>
                <div v-if="stab === 2">
                    <div class="mb-4">
                        <span class="text-md pa-0" v-if="join.status === '2'">
                            <v-icon size="13" class="primary--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}
                            </v-icon><span class="primary--text text-caption">Confirmed</span>
                        </span>
                        <span class="text-md pa-0" v-else-if="join.status === '0'">
                            <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
                            <span class="error--text text-caption">Rejected</span>
                        </span>

                        <span class="text-md pa-0" v-else>
                            <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon><span
                                class="secondary--text text-caption">awaiting</span>
                        </span>
                    </div>
                    <v-btn x-small v-if="join.status === '1'" color="error" width="auto"
                        class="px-4 w-full lightError mt-3 ml-auto" outlined @click="cancelInvitation(join)"
                        :loading="onCancel === true ? true : false">
                        Cancel
                    </v-btn>
                </div>

            </v-list-item-action>
        </v-list-item>
    </v-card> -->
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb, mdiCheckboxMarkedCircle, mdiAlertCircle, mdiCloseCircle, mdiSortCalendarAscending } from '@mdi/js'
import { ref } from '@vue/composition-api'
var md5 = require("md5")
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        join: {
            type: Object,
            default: {}
        },
        stab: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            onGoingOperation: false,
            onConf: false,
            onDel: false,
            onCancel: false,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        }
    },
    methods: {
        acceptInvitation(item) {
            this.onConf = true
            this.$emit('accept', item)
        },
        deleteInvitation(item) {
            this.onDel = true
            this.$emit('delete', item)
        },
        cancelInvitation(item) {
            this.onCancel = true
            this.$emit('cancel', item)
        },
        viewUserInfos() {
            this.$router.push({ name: "user.views", params: { f_id: md5(this.join.user.id), ids: this.join.user.id } })
        },
        o_md5(root_link) {
            return md5(root_link)
        }
    },
    setup(props) {
        const icons = { mdiMapMarkerOutline, mdiWeb, mdiCheckboxMarkedCircle, mdiAlertCircle, mdiCloseCircle, mdiSortCalendarAscending }

        const urlimg = Drequest.__envimg
        return {
            icons, urlimg
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';
</style>
