<template>
    <v-app>
        <header-component :isNotCompany="navadress === 'dashu'" :drawerStatus="isDrawerOpen"></header-component>
        <drawer-menu :isNotCompany="navadress === 'dashu'" :is-drawer-open.sync="isDrawerOpen"></drawer-menu>
        <v-main class="mt-4">
            <!-- <v-layout class="layout-container"> -->
            <!-- <drawer-menu v-if="navadress == 'dashu'"></drawer-menu> -->
            <!-- <company-menu v-if="navadress == 'dashc'" :is-drawer-open.sync="isDrawerOpen"></company-menu> -->
            <div class="main-layout-container">
                <!-- Main area -->
                <!-- {{ isDrawerOpen }} -->
                <div class="ma-4 d-flex flex-column">
                    <div class="d-flex flex-row">
                        <v-btn icon class="subsecondary" @click="isDrawerOpen = !isDrawerOpen">
                            <v-icon color="secondary"> {{ icons.mdiMenu }}</v-icon>
                        </v-btn>
                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="#"
                                        class="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="mr-4 h-4 w-4">
                                            <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <span class="mx-2.5 text-gray-800 ">/</span>
                                        <a href="#"
                                            class="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2">
                                            {{short_navigation.mainnav}}
                                        </a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <span class="mx-2.5 text-gray-800 ">/</span>
                                        <span class="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2">
                                            
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <v-divider class="mt-2 mb-4"></v-divider>
                    <slot>
                        <home-company v-if="navadress == 'dashc'"></home-company>
                        <user-home v-if="navadress == 'dashu'"></user-home>
                    </slot>
                </div>
            </div>
            <!-- </v-layout> -->
            <footer-component></footer-component>
        </v-main>
    </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import DrawerMenu from '@/views/dashboard/components/vertical-nav-menu/DrawerMenu.vue'
import FooterComponent from '@/layouts/components/FooterComponent.vue'
import HeaderComponent from '@/layouts/components/HeaderComponent.vue'
import HomeCompany from '@/views/dashboard/company/Account.vue'
// import HomeCompany from '@/views/dashboard/company/HomeCompany.vue'
import UserHome from '@/views/dashboard/professional/UserHome.vue'
import CardNotification from '@/views/dashboard/components/notifications/CardNotification.vue'
import {
    mdiTriangle, mdiCircle, mdiSquare,
    mdiMagnify, mdiBellOutline, mdiGithub, mdiAppleKeyboardControl, mdiPhoneOutline,
    mdiEmailOutline, mdiDomain, mdiMenu, mdiCollapseAll, mdiCollapseAllOutline,

    mdiHomeCity,
    mdiChevronLeft,
    mdiAccount,
    mdiAccountGroupOutline
}
    from '@mdi/js'



export default {
    components: {
        HeaderComponent,
        DrawerMenu,
        FooterComponent,
        UserHome,
        HomeCompany,
        CardNotification,
    },
    data() {

        return {
            drawer: true,
            rail: true,
            drawer: null,
            navadress: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() == "/dashc".toUpperCase()) ? 'dashc' : 'dashu',
            menuItems: [
                { text: 'Home', route: '/', icon: 'mdi-home' },
                { text: 'About', route: '/about', icon: 'mdi-information' },
                // Add more menu items as needed
            ],
            drawer: true,
            items: [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
            mini: false,
            short_navigation: {
                mainnav: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() == "/dashc".toUpperCase()) ? 'Enterprise' : 'Professional',
                subnav: ""
            }
        }

    },

    watch: {
        $route: {
            handler: function (newRouteValue) {
                let company = "/dashc"
                let professional = "/dashu"
                if ((this.$route.path).substr(0, company.length).toUpperCase() == company.toUpperCase()) {
                    this.navadress = 'dashc'
                    this.short_navigation.mainnav = "Enterprise"
                }
                if ((this.$route.path).substr(0, professional.length).toUpperCase() == "/dashu".toUpperCase()) {
                    this.navadress = 'dashu'
                    this.short_navigation.mainnav = "Professional"
                }
            },
            deep: true
        }
    },

    setup() {
        const isDrawerOpen = ref(null)
        var handlermenu = ref('')
        const drawer = ref(null)
        // Icons
        const icons = {
            mdiMagnify, mdiBellOutline, mdiGithub, mdiAppleKeyboardControl, mdiPhoneOutline,
            mdiEmailOutline, mdiDomain, mdiMenu, mdiCollapseAll, mdiCollapseAllOutline, mdiTriangle, mdiCircle, mdiSquare,
            mdiHomeCity,
            mdiChevronLeft,
            mdiAccount,
            mdiAccountGroupOutline
        }

        return {
            icons,
            drawer,
            isDrawerOpen
        }
    },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
    .v-toolbar__content {
        padding: 0;

        .app-bar-search {
            .v-input__slot {
                padding-left: 18px;
            }
        }
    }
}

.layout-container {
    position: relative;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    width: 100%;


    .drawer-nav-menu {}

    .main-layout-container {
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

/*
.main-app-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;

    .main-app-drawer {
        min-width: 14.5em !important;
        max-width: 14.5em !important;
        height: auto;
    }

    .main-app-section {
        width: 100%;
        padding: 0px 1em;
    }
}

.main-app-header {
    display: none;
}


.main-app-content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;

    .main-app-drawer {
        min-width: 14.5em !important;
        max-width: 14.5em !important;
        height: auto;
    }

    .main-app-section {
        width: 100%;
        padding: 0px 1em;
    }
}

@media (max-width: 719px) {
    .main-app-drawer {
        //display: none;
        position: absolute;
        z-index: 100;
    }

    .main-app-header {
        display: flex;
    }
}*/
</style>
