<template>
  <div>
    <v-app-bar app short elevation="0" color="white" class="mainappbar">
      <v-toolbar-title v-if="drawerStatus !== true">
        <router-link to="/home" class="text-decoration-none">
          <v-img :src="require('@/assets/images/logos/logo.svg')" max-width="7em" min-width="6em" alt="Duylaba">
          </v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <!-- Tab items -->
      <v-toolbar-items class="toolbar-nav">
        <!-- <div @click="drawerStatus = !drawerStatus">{{ drawerStatus }}</div> -->
        <div class="hidden-sm-and-down header-toolbar-nav">
          <!-- <div class="hidden-sm-and-down header-toolbar-nav" v-if="drawerStatus !== true"> -->
          <template v-if="this.$store.getters.isLoggedIn === false">
            <router-link v-for="(item, i) in navigation_main" :key="i" :to="{ name: item.link }" target=""
              class="toolbar-nav-item">
              <div class="header-toolbar-nav-button">
                <v-icon class="toolbar-nav-item-icon nav-icon">{{ item.icon }}</v-icon>
                <!-- <span v-if="drawerStatus === false">{{ item.title }}</span> -->
                <span>{{ item.title }}</span>
              </div>
            </router-link>
          </template>
          <template v-else>
            <router-link v-for="(item, i) in (isNotCompany === true ? navigation_sm.user : navigation_sm.company)" :key="i"
              :to="{ name: item.link }" target="" class="toolbar-nav-item">
              <div class="header-toolbar-nav-button">
                <v-icon class="toolbar-nav-item-icon nav-icon">{{ item.icon }}</v-icon>
                <span v-if="drawerStatus !== true">{{ item.title }}</span>
              </div>
            </router-link>
          </template>
        </div>

        <div class="h-full xl:flex items-center justify-end ml-4" v-if="this.$store.getters.isLoggedIn === true">
          <div class="w-full h-full flex items-center hidden-md-and-up">
            <div class="w-full pr-10 h-full flex items-center">
              <div class="relative w-full">
                <div class="text-gray-600 absolute ml-2 inset-0 m-auto w-5 h-5">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/light_with_Grey_background-svg3.svg"
                    alt="search">
                </div>
                <input
                  class="hidden-md-and-down border border-gray-100 focus:outline-none focus:border-indigo-700 w-56 rounded text-sm text-gray-500 placeholder-gray-600 bg-gray-100 pl-8 py-2"
                  type="text" placeholder="Search" />
              </div>
            </div>
            <div class="hidden-md-and-down w-full h-full flex mr-4">
              <div class="h-full flex items-center justify-center cursor-pointer text-gray-600">
                <a aria-label="show notifications" role="link" href="javascript:void(0)"
                  class="cursor-pointer w-6 h-6 xl:w-auto xl:h-auto text-gray-600">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/light_with_Grey_background-svg6.svg"
                    alt="notifications">
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden-md-and-up mx-4">
          <nav-menu-item-sm
            :navbarData="(this.$store.getters.isLoggedIn === false ? navigation_main : (isNotCompany === true ? navigation_sm.user : navigation_sm.company))">
          </nav-menu-item-sm>
        </div>
        <router-link :to="{ name: 'login' }" target="" v-if="this.$store.getters.isLoggedIn === false"
          class="text-decoration-none">
          <v-btn text class="secondary" small rounded>
            Log in
          </v-btn>
        </router-link>

        <template v-if="this.$store.getters.isLoggedIn === true">
          <app-bar-user-menu :isNotCompany="isNotCompany">
          </app-bar-user-menu>
        </template>
        <!-- <div class="mx-2">
          <nav-lang></nav-lang>
        </div> -->
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import AppBarUserMenu from './NavBarMenu/AppBarUserMenu.vue'
import NavMenuItemSm from './NavBarMenu/NavMenuItemSm.vue'
import AppBarNotification from './NavBarMenu/AppBarNotification.vue'
import NavMenuLink from '@/views/dashboard/components/vertical-nav-menu/components/NavMenuLink.vue'
import NavLang from './NavBarMenu/NavLang.vue'

import {
  mdiMenuDown,
  mdiMenuUp,
  mdiMagnify,

  mdiHome,
  mdiInformation,
  mdiCreditCardScan,
  mdiCardAccountMail,
  mdiPhone,
  mdiAccountTie, mdiBriefcase, 
  mdiAccountGroup, mdiWallet, mdiForum, mdiEvPlugType1, mdiCalendarEdit, mdiBriefcaseSearch, mdiOfficeBuildingMarker, mdiAccountCog,
  mdiViewDashboard, mdiAccountConvert, mdiWan, mdiCollapseAll, mdiMessageText,
}
  from '@mdi/js'

export default {
  components: {
    AppBarUserMenu,
    NavMenuLink,
    NavMenuItemSm,
    AppBarNotification,
    NavLang
  },
  props: {
    drawerStatus: {
      type: Boolean,
      default: false,
    },
    isNotCompany: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isClicked: false,
    }
  },
  methods: {
    menuItems() {
      return this.menu;
    },
    navigateToLink(value) {
      this.$router.push({ name: value.toString() })
    }
  },
  setup() {
    const menuNavOthe = [
      {
        title: 'Pricing',
        link: 'pricing',
        icon: mdiCreditCardScan
      },
      {
        title: 'About',
        link: 'about',
        icon: mdiInformation
      },
      {
        title: 'Contact',
        link: 'contact',
        icon: mdiCardAccountMail
      },
    ]

    const navigation_main = [
      {
        title: 'Home',
        link: 'home',
        icon: mdiHome
      },
      {
        title: 'About us',
        link: 'about',
        icon: mdiInformation
      },
      {
        title: 'Companies',
        link: 'company',
        icon: mdiOfficeBuildingMarker
      },
      {
        title: 'Professionals',
        subtitle: 'Hire talents and parthners',
        link: 'professional',
        icon: mdiAccountTie
      },
      {
        title: 'Calls for proposals',
        subtitle: 'Get most recently proposals',
        link: 'proposal.list',
        icon: mdiEvPlugType1
      },
      {
        title: 'Vacancies',
        subtitle: 'Search & Apply to Jobs',
        link: 'vacancies.list',
        icon: mdiBriefcaseSearch
      },
      // {
      //   title: 'Pricing',
      //   link: 'pricing',
      //   icon: mdiCreditCardScan
      // },
      // {
      //   title: 'About Us',
      //   link: 'about',
      //   icon: mdiInformation
      // },
      {
        title: 'Contact',
        link: 'contact',
        icon: mdiCardAccountMail
      },
    ]
    const navigation_sm = {
      user: [
        // {
        //   title: 'Dashboard',
        //   icon: mdiViewDashboard,
        //   link: 'user.account',
        //   divider: false
        // },
        {
          title: 'Home',
          icon: mdiHome,
          // icon: mdiHome,
          link: 'user.home',
          divider: false
        },
        {
          title: 'Vacancies',
          icon: mdiBriefcaseSearch,
          link: 'user.vacancies.list',
          divider: false
        },
        {
          title: 'Networking',
          icon: mdiAccountGroup,
          link: 'user.join.list',
          divider: false
        },
        {
          title: 'Enterprises',
          icon: mdiOfficeBuildingMarker,
          link: 'user.list.company',
          divider: false
        },
        {
          title: 'Blog',
          icon: mdiForum,
          link: 'user.blog',
        },
        {
          title: 'Event',
          icon: mdiCalendarEdit,
          link: 'user.event',
        },
      ],
      company: [
        {
          title: 'Home',
          icon: mdiHome,
          link: 'company.home',
        },
        {
          title: 'Services',
          icon: mdiWallet,
          link: 'company.service',
        },
        {
          title: 'RFP',
          icon: mdiEvPlugType1,
          link: 'company.rfp'
        },
        {
          title: 'RFC',
          icon: mdiAccountConvert,
          link: 'company.rfc',
        },
        {
          title: 'Vacancies',
          icon: mdiBriefcaseSearch,
          link: 'company.vacancies',
        },

        {
          title: 'Blog',
          icon: mdiForum,
          link: 'user.blog',
        },
        {
          title: 'Event',
          icon: mdiCalendarEdit,
          link: 'user.event',
        },
      ]
    }
    const isDrawerOpen = ref(null)
    return {
      isDrawerOpen,
      menuNavOthe,
      navigation_main,
      navigation_sm,
      // Icons
      icons: {
        mdiMagnify,

        mdiPhone,
        mdiMenuDown,
        mdiMenuUp,

        mdiAccountTie, mdiBriefcase,
        mdiAccountGroup, mdiWallet, mdiForum, mdiEvPlugType1, mdiCalendarEdit, mdiBriefcaseSearch, mdiOfficeBuildingMarker, mdiAccountCog,
        mdiViewDashboard, mdiAccountConvert, mdiWan, mdiCollapseAll, mdiMessageText,
        mdiInformation
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.fixe-nav {
  position: relative;
}

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.toolbar-nav {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  justify-content: center;
  align-items: center;
  //align-content: center;
}

.toolbar-nav-item {
  width: max-content;
  text-decoration: none;
  //color: var(--v-secondary-base);
  color: #333333;
  margin-right: 1rem;

}

.v-app-bar {
  height: fit-content !important;
  padding: 0.5rem 1rem !important;
}

.mainappbar {
  z-index: 100 !important;
}

/*.toolbar-nav-item:hover, .toolbar-nav-item-icon:hover {
  color: var(--v-primary-base);
}*/


a,
a .toolbar-nav-item-icon {
  opacity: .9;
  //font-weight: 550;
  transition: .6s;
}

a:hover,
a .toolbar-nav-item-icon:hover {
  color: var(--v-secondary-base);
  //font-weight: 500;
  opacity: 1;
}

a.router-link-active,
a.router-link-active .toolbar-nav-item-icon {
  color: var(--v-secondary-base);
  opacity: 1;
}

.header-toolbar-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .header-toolbar-nav-button {
    display: flex;
    flex-direction: column;
    //margin: 5px 2px;
    font-size: 10pt;
    justify-content: center;
    align-items: center;
    margin: 2px 5px;

    .nav-icon {
      font-size: 10pt;
      font-weight: 300;
      width: 1.4em;
      height: 1.4em;
      padding: 0px;
      //border: solid 1px #000;
    }
  }
}
</style>
