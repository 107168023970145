<template>
  <div class="">
    <!-- <div class="w-full"> -->
      <header-profil to="professional" :profil="user">
        <div class="flex flex-row text-left">
          <div class="px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold">{{ user.lastname + " " + user.firstname }}</h1>
            <!-- <p class="text-lg font-medium">Phd/Master en urban planning</p> -->
          </div>
          <v-spacer></v-spacer>
          <div cols="12" v-if="showactivatedbtn == 0">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small class="suberror text-capitalize">
                  <span class="text-sm error--text" v-bind="attrs" v-on="on">Activate your account</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="text-h5 font-weight-black primary secondaryAccent--text">
                  Active your account
                </v-card-text>

                <v-card-text class="text-h6">
                  Enter activation you have received
                </v-card-text>
                <v-card-text class="my-1">
                  <v-text-field v-model="activatedcode" label="" dense outlined>
                  </v-text-field>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialog = false">
                    Reset
                  </v-btn>
                  <v-btn color="primary" outlined :loading="this.loading ? true : false" @click="activateAccount">
                    Activate
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="mx-auto mt-2 px-4 sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div class="overflow-hidden sm:rounded-lg dark:bg-gray-900">
              <div class="text-gray-700">{{user.profession}}</div>
              <div class="text-gray-700">{{user.experience_year}} year of experience</div>
              <div class="text-gray-700">{{user.country.name}}</div>
              <div class="italic text-gray-500" v-if="user.profession">{{ user.profession }} at {{ user.namecompany }}....</div>
            </div>
          </div>
        </div>
      </header-profil>
    <!-- </div> -->


    <!-- <search-users></search-users> -->


<!--  -->
<header-tab :tabs="['Profile', 'Activities', 'Infos', 'Settings']" @tab="toggleTabs" class="mt-8"></header-tab>

    <!-- <ul class="nav-dash-tab mt-4">
      <li v-for="(tab, i) in tabs" :key="i" class="font-bold uppercase" v-on:click="toggleTabs(i + 1)"
        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
        {{ tab.title }}
      </li>
    </ul> -->

    <div class="col-span-12 sm:col-span-12" v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">

      <!-- Section: Contacts -->
      <div class="bg-white pa-4">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 class="font-semibold text-gray-700">Adresse</h3>
            <p class="text-gray-700">{{ user.address }}</p>
          </div>
          <div>
            <h3 class="font-semibold text-gray-700">Téléphone</h3>
            <p class="text-gray-700">{{ user.phonenumber }}</p>
          </div>
          <div>
            <h3 class="font-semibold text-gray-700">Email</h3>
            <p>
              <a href="mailto:mahop@alain.org" class="text-blue-500 hover:underline">{{ user.email }}</a>
            </p>
          </div>
        </div>
      </div>

      <div class="bg-white pa-4 mt-4">
        <div class="grid grid-cols-1 md:grid-cols-1 gap-8">
          <div>
            <h3 class="font-semibold text-gray-700">About me</h3>
            <p class="text-gray-700">{{ user.about }}</p>
          </div>
        </div>
      </div>
      <!-- Section: Experiences, Education, Hobbies -->
      <div class="bg-white pa-4 mt-4">
        <div>
          <h3 class="text-xl font-semibold">Experiences</h3>
          <p class="text-gray-700"> </p>
        </div>
        <div>
          <h3 class="text-xl font-semibold">Education</h3>
          <p class="text-gray-700"> </p>
        </div>
        <div>
          <h3 class="text-xl font-semibold">Hobbies</h3>
          <p class="text-gray-700"> </p>
        </div>
      </div>

      <!-- Section: My Applications -->
      <div class="bg-white pa-4 mt-4">
        <h3 class="text-xl font-semibold mb-4">My Applications</h3>
        <!-- <div class="space-y-4 mt-4">
          <div class="flex justify-between items-center bg-gray-50 p-4 rounded-lg">
            <p> </p>
            <button class="text-blue-500 hover:underline">View more</button>
          </div>
          <div class="flex justify-between items-center bg-gray-50 p-4 rounded-lg">
            <p> </p>
            <button class="text-blue-500 hover:underline">View more</button>
          </div>
        </div>
        </div> -->

        <job-replying></job-replying>
      </div>

      <!-- Section: My Network -->
      <div class="bg-white pa-4 mt-4">
        <h3 class="text-xl font-semibold">My Network</h3>
        <div class="grid grid-cols-3 gap-8 mt-4">
          <!-- Bomba -->
          <div class="flex flex-col items-center">
            <p>Bomba</p>
            <img src="https://via.placeholder.com/50" class="rounded-full w-16 h-16" alt="Bomba">
          </div>
          <!-- Tchoups -->
          <div class="flex flex-col items-center">
            <p>Tchoups</p>
            <img src="https://via.placeholder.com/50" class="rounded-full w-16 h-16" alt="Tchoups">
          </div>
          <!-- Diby -->
          <div class="flex flex-col items-center">
            <p>Diby</p>
            <img src="https://via.placeholder.com/50" class="rounded-full w-16 h-16" alt="Diby">
          </div>
        </div>
      </div>

      <!-- Section: My Blogs -->
      <div class="bg-white pa-4 mt-4">
        <h3 class="text-xl font-semibold">My Blogs</h3>
        <div class="flex justify-between items-center bg-gray-50 p-4 rounded-lg mt-4">
          <p>   </p>
          <div class="flex space-x-4">
            <button class="text-blue-500 hover:underline">View</button>
            <button class="text-blue-500 hover:underline">Edit</button>
            <button class="text-red-500 hover:underline">Delete</button>
          </div>
        </div>
      </div>
      <!-- Add Button -->
      <div class="mt-8">
        <button class="bg-blue-500 text-white px-6 py-2 rounded-lg">Add +</button>
      </div>
    </div>


    <div class="col-span-12 sm:col-span-12" v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
      <div class="bg-white shadow rounded-lg p-6">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Summary</h2>
        <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
          <div class="grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
              <div class="p-4 sm:p-6">
                <dl v-if="loader.company.loading === false">
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                    {{ datas.company.title }}</dt>
                  <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                    {{ datas.company.nb_element }}
                  </dd>
                </dl>
                <dl v-else>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </dl>
              </div>
            </div>
            <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
              <div class="px-4 py-5 sm:p-6">
                <dl v-if="loader.rfc.loading === false">
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                    {{ datas.rfc.title }}</dt>
                  <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                    {{ datas.rfc.nb_element }}
                  </dd>
                </dl>
                <dl v-else>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </dl>
              </div>
            </div>
            <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
              <div class="px-4 py-5 sm:p-6">
                <dl v-if="loader.vacancies.loading === false">
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                    {{ datas.vacancies.title }}</dt>
                  <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                    {{ datas.vacancies.nb_element }}
                  </dd>
                </dl>
                <dl v-else>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </dl>
              </div>
            </div>
            <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
              <div class="px-4 py-5 sm:p-6">
                <dl v-if="loader.relations.loading === false">
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                    {{ datas.relations.title }}</dt>
                  <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                    {{ datas.relations.nb_element }}
                  </dd>
                </dl>
                <dl v-else>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white shadow rounded-lg p-6 mt-4">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Activity
        </h2>
        <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
          <card-linear-chart :datasheet="activitySheet"></card-linear-chart>
        </div>
      </div>
    </div>


    <div class="col-span-12 sm:col-span-12" v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
      <!-- <div class="bg-white shadow rounded-lg pa-5">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">About
            Me</h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-4 lg:px-8">
            <v-col cols="12">
              <v-card class="mb-4 rounded-lg">
                <div class="pa-4">
                  About me
                </div>
                <v-divider></v-divider>
                <v-card-text class="">
                  {{ user.about }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mb-4 rounded-lg">
                <div class="pa-4">
                  Contact Informations
                </div>
                <v-divider></v-divider>
                <v-card-text class="d-flex pa-0">
                  <div class="col-md-5">Phone number</div>
                  <div class="col-md-7">{{ user.phonenumber }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="d-flex pa-0">
                  <div class="col-md-5">Email </div>
                  <div class="col-md-7">{{ user.email }}</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mb-4 rounded-lg">
                <div class="pa-3">
                  My skills:
                </div>
                <v-divider></v-divider>
                <v-card-text class="" v-if="userskill">
                  <div v-for="(skil, cle) in userskill" :key="cle">{{ skil.fr }}</div>
                </v-card-text>
                <v-card-text v-else class="text-center">You have empty skill for the moment..!</v-card-text>
              </v-card>
            </v-col>
          </div>
        </div> -->
      <div class="bg-white shadow rounded-lg pa-5 mt-4">
        <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
          <h5 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white mb-4">Setup
            account
            here</h5>
          <account-settings></account-settings>
        </div>
      </div>
    </div>
    
    <div class="col-span-12 sm:col-span-12" v-bind:class="{ 'hidden': openTab !== 4, 'block': openTab === 4 }">
      <div class="bg-white shadow rounded-lg pa-5 mt-4">
        <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
          <h6 class="text-2xl font-extrabold tracking-tight text-gray-500 sm:text-5xl dark:text-white mb-4">Change password</h6>
          <account-security></account-security>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline,
  mdiAlertOutline, mdiCloudUploadOutline, mdiPhone, mdiMessageText,
  mdiMapMarker, mdiEmail, mdiWeb, mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
  mdiCalendarRange, mdiMapMarkerOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import AccountSettings from './account/AccountSettings.vue'
import AccountSecurity from './account/AccountSecurity.vue'
import AccountInfos from './account/AccountInfos.vue'
import HeaderProfil from '@/views/dashboard/components/HeaderProfil.vue'
import CardLinearChart from '@/views/dashboard/components/CardLinearChart.vue'
import JobReplying from '@/views/dashboard/professional/vacancies/JobReplying.vue';
import HeaderTab from '@/views/dashboard/components/HeaderTab.vue'
// @override
import SearchUsers from '@/views/pages/components/SearchUsers.vue'

export default {
  components: {
    AccountSettings,
    AccountSecurity,
    AccountInfos,
    HeaderProfil,
    CardLinearChart,
    JobReplying,
    SearchUsers,
    HeaderTab
  },
  data() {
    return {
      activitySheet: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Call for colaboration',
          data: [12, 15, 3, 5, 2, 3, 14],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1
        },
        {
          label: 'Vacancies',
          data: [0, 2, 4, 1, 4, 6, 2],
          backgroundColor: 'rgba(0, 0, 255, 0.2)',
          borderColor: 'rgba(0, 0, 255, 0.4)',
          borderWidth: 1
        },
        {
          label: 'Realations',
          data: [8, 19, 3, 8, 2, 12, 6],
          backgroundColor: 'rgba(11, 156, 49, 0.2)',
          borderColor: 'rgba(11, 156, 49, 0.8)',
          borderWidth: 1
        }]
      },
      editAccount: false,
      active_tab: 'account.profile',
      user: {},
      tabs: [
        { title: 'Profile', icon: mdiLockOpenOutline, link: { name: 'user.account.pass' } },
        { title: 'Activities', icon: mdiAccountOutline, link: { name: 'user.account.profile' } },
        { title: 'Infos', icon: mdiAccountOutline, link: { name: 'user.account.setting' } },
        { title: 'Settings', icon: mdiAccountOutline, link: { name: 'user.account.setting' } },
        // { title: 'Security', icon: mdiLockOpenOutline, link: { name: 'user.account.pass' } },
        // { title: 'Info', icon: mdiInformationOutline },
      ],
      openTab: 1,
      user: {},
      userskill: [],
      // user: this.$store.getters.getUser,
      showactivatedbtn: this.$store.getters.getUser.isactivated,
      dialog: false,
      loading: false,
      loading: {
        info: true
      },

      status: ['Active', 'Inactive', 'Pending', 'Closed'],
      activatedcode: '',
      datas: {
        relations: {
          title: 'Networking',
          values: {},
          nb_element: 0,
          sent: {
            title: "",
            value: 0
          },
          received: 0,
          confirm: 0
        },
        vacancies: {
          title: "My vacancies",
          values: {},
          nb_element: 0
        },
        rfc: {
          title: "Call for colaboration",
          values: {},
          nb_element: 0
        },
        company: {
          title: "My companies",
          values: {},
          nb_element: 0
        }
      },
      loader: {
        vacancies: {
          loading: true,
          valueReady: false
        },
        relations: {
          loading: true,
          valueReady: false
        },
        rfc: {
          loading: true,
          valueReady: false
        },
        company: {
          loading: true,
          valueReady: false
        },
      }
    }
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    },
    editMyProfile() {
      this.$router.push({ name: 'account.setting' })
    },
    fetchUserInformation() {
      Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
        if (response.success === true) {
          this.user = response.listEntity[0]
        }
      }).catch((err) => { });

      Drequest.api(`lazyloading.user-skill?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
        if (response.success === true) {
          response.listEntity.forEach(element => {
            this.userskill.push(element.skill.name)
          });
        }
        else { }
      }).catch((err) => { });
    },

    editMyProfile() {
      this.$router.push({ name: 'account.setting' })
    },

    // fetchUserInformation() {
    //   Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //     if (response.success === true) {
    //       this.user = response.listEntity[0]
    //     }
    //   }).catch((err) => { });

    //   Drequest.api(`lazyloading.user-skill?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //     if (response.success === true) {
    //       response.listEntity.forEach(element => {
    //         this.userskill.push(element.skill.name)
    //       });
    //     }
    //     else { }
    //   }).catch((err) => { });
    // },

    activateAccount() {
      var formreset = new FormData();
      formreset.append("activationcode", this.activatedcode);
      this.loading = true;
      Drequest.api(`user.activateaccount?user_id=${this.$store.getters.getUserId}`)
        .data(formreset)
        .post((response) => {
          switch (response.success) {
            case true:
              this.loading = false;
              this.dialog = false
              this.showactivatedbtn = response.user.is_activate
              this.$store.commit("setUser", {
                id: response.user.id,
                isactivated: response.user.is_activated,
              });

              this.$fire({
                type: "success",
                text: response.detail,
              })
              break;
            default:
              this.loading = false; this.$fire({
                type: "error",
                text: "Bad activation code.! \n please check your email adress.!",
              })
              break;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.fetchUserInformation()

    Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.datas.company.nb_element = response.nb_element
        this.loader.company.loading = false
        this.loader.company.valueReady = true
      }
      else {
        this.loader.loading = false
        this.loader.valueReady = false
      }
    }).catch((err) => {
      this.loader.company.loading = false
      this.loader.company.valueReady = false
    });
    // Drequest.api(`lazyloading.colaborator?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //   if (response.success === true) {
    //     this.datas.rfc.nb_element = response.nb_element
    //     // this.dashservices[0].items.push({ title: 'Sent', value: response.nb_element })

    //     this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = true
    //   }
    //   else {
    //     this.dashservices[0].items.push({ title: 'Sent', value: 0 }),
    //       this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = false
    //   }
    // }).catch((err) => {
    //   this.loader.rfc.loading = false
    //   this.loader.rfc.valueReady = false
    // });

    // Drequest.api(`lazyloading.colaborator?dfilters=on&recipientuser:eq=${this.$store.getters.getUser.id}`).get((response) => {
    Drequest.api(`lazyloading.colaborator?dfilters=on&user_id=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.datas.relations.nb_element = response.nb_element
        this.loader.relations.loading = false
        this.loader.relations.valueReady = true
      }
      else {
        this.loader.relations.loading = false
        this.loader.relations.valueReady = false
      }
    }).catch((err) => {
      this.loader.relations.loading = false
      this.loader.relations.valueReady = false
    });
    //   Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`).get((response) => {
    //     if (response.success === true) {
    //       this.networkdata.confirm = response.nb_element
    //       // this.dashservices[0].items.push({ title: 'Colaborators', value: response.nb_element })
    //     }
    //     else { this.dashservices[0].items.push({ title: 'Colaborators', value: 0 }) }
    //   }).catch((err) => { });
    Drequest.api(`lazyloading.rfcuser?dfilters=on&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.datas.rfc.nb_element = response.nb_element
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = true
      }
      else {
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = false
      }
    }).catch((err) => {
      this.loader.rfc.loading = false
      this.loader.rfc.valueReady = false
    });
    Drequest.api(`lazyloading.jobresponse?dfilters=on&user.id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.datas.vacancies.nb_element = response.nb_element
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = true
      }
      else {
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = false
      }
    }).catch((err) => {
      this.loader.vacancies.loading = false
      this.loader.vacancies.valueReady = false
    });
    // Drequest.api(`lazyloading.rfcuser?dfilters=on&status:eq=2&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //   if (response.success === true) {
    //     this.rfcdata.confirm = response.nb_element
    //     // this.dashservices[1].items.push({ title: 'Confirmed', value: response.nb_element }) 
    //   }
    //   else {
    //     // this.dashservices[1].items.push({ title: 'Confirmed', value: 0 }) 
    //   }
    // }).catch((err) => { });
  },
  setup(props) {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
        mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
        mdiCalendarRange, mdiMapMarkerOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';
@import '@/styles/tabs.scss';

.avatar-center {
  top: -3rem;
  left: 3rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}

.button-bar {
  display: flex;
  justify-content: center;
}

.button-bar button {
  background-color: #4CAF50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.button-bar button:hover {
  background-color: #45a049;
}

.button-bar button:active {
  background-color: #3e8e41;
}
</style>
