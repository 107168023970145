<template>
    <div>
        <div id="misc">
            <div class="page-title text-center px-5">
                <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
                    <span class="me-2">No post yet</span>
                    <v-icon color="warning">
                        {{ icons.mdiAlert }}
                    </v-icon>
                </h2>
                <p class="text-sm">
                    The blog page will comming soon..
                </p>

                <div class="misc-character d-flex justify-center">
                    <v-img max-width="700" src="@/assets/images/pages/cuve-line.svg"></v-img>
                </div>

                <v-btn color="primary" :to="{ name: 'user.home' }" class="mb-4">
                    Back to home
                </v-btn>
            </div>
        </div>
        <!-- <v-card tile flat>
            <v-card-text v-if="sortRecipientsItems.length <= 0" class="text-center">
                <empty-data :text="nodatas"></empty-data>
            </v-card-text>
            <template v-else>
                <blogue-card v-for="(msg, id) in sortRecipientsItems" :key="id" :post="msg"></blogue-card>
            </template>
</v-card>
<v-list-item class="rounded-lg">
    <v-list-item-avatar class="">
    </v-list-item-avatar>
    <v-list-item-content class="rounded-lg ">
        <v-card-text class="d-flex flex-row justify-center align-top px-0">
            <pick-photo @showfile="showfiledata($event)" class="mr-2">
                <v-btn class="subsecondary" icon>
                    <v-icon color="secondary">{{ icons.mdiCamera }}</v-icon>
                </v-btn>
            </pick-photo>
            <div class="d-flex flex-column justify-top align-center px-0 w-full mb-10"> -->
        <!-- <v-text-field v-model="inputmessage" v-on:keyup.enter="sendMessageBlog" class="w-full"
                            placeholder="Tape your message here" dense outlined hide-details clearable></v-text-field> -->
        <!-- <vue-editor v-model="inputmessage" v-on:keyup.enter="sendMessageBlog" class="blog-vue-editor text-left mb-3"
                            placeholder="Tape your message here" dense outlined clearable>
                        </vue-editor> -->

        <!-- <vue-editor v-model="inputmessage" outlined dense
                                        :hide-details="rfpcontentErrors.isvalid"
                                        :error-messages="rfpcontentErrors.value" class="text-left mb-3" label="Body"
                                        placeholder="Lorem ipsum dolor sit amet. Non magnam asperiores in libero sint">
                                    </vue-editor> -->
        <!-- </div>
                    <v-btn text color="secondary" class="subsecondary rounded-lg ml-3 px-1" @click="sendMessageBlog">
                        Send <v-icon class="ml-2">{{ icons.mdiSend }}</v-icon>
                    </v-btn>
                </v-card-text>
                <div class="d-flex justify-top align-center px-0 w-full mx-auto" width="auto" v-if="this.image">
                    <v-img class="rounded-lg mx-auto" max-height="10em" :alt="''" :src="this.image" center>
                        <v-btn class="error" icon small @click="removeImage">
                            <v-icon color="white">{{ icons.mdiCloseThick }}</v-icon>
                        </v-btn>
                    </v-img>
                </div>
            </v-list-item-content>
        </v-list-item> -->
    </div>
</template>

            <script>
            import { ref } from '@vue/composition-api'
            import { mdiMagnify, mdiCamera, mdiSend, mdiCloseThick } from '@mdi/js'
            import { Drequest } from '@/plugins/Drequest'
            import { VueEditor } from "vue2-editor";
            import BlogueCard from '@/views/pages/company/components/BlogueCard.vue'
            import PickPhoto from '@/views/pages/components/PickPhoto.vue'
            import EmptyData from '@/views/components/utils/EmptyData.vue';

            // var md5 = require("md5")
            export default {
                components: {
                    PickPhoto,
                    BlogueCard,
                    EmptyData,
                    VueEditor
                },
                data() {
                    return {
                        inputmessage: '',
                        lisOfPostmessage: [],
                        image: '',
                        file: null,
                        nodatas: 'post'
                    };
                },
                created() {
                    // this.initialize()
                },
                methods: {
                    createImage(file) {
                        var image = new Image();
                        var reader = new FileReader();
                        var vm = this;
                        reader.onload = (e) => {
                            vm.image = e.target.result;
                        };
                        reader.readAsDataURL(file);
                    },

                    showfiledata(e) {
                        this.file = e.file
                        this.createImage(e.file)
                    },

                    sendMessageBlog() {
                        const post = {
                            owner: this.$store.getters.getUser.firstname,
                            content: this.inputmessage,
                            sent_at: Date().toLocaleString(),
                            isloaded: false,
                            imag: this.image
                        }
                        this.image = ''
                        this.inputmessage = ''
                        this.lisOfPostmessage.push(post)

                    },


                    removeImage: function (e) {
                        this.image = '';
                    },
                    uploadAnSendImage() {
                        var formdata = new FormData();
                        console.log(this.mfiles)
                        formdata.append("image", this.mfiles);
                        Drequest.api("fileupload")
                            .data(formdata)
                            .post((response) => {
                                if (response.success) {
                                    // service_id
                                    const imageToDB = {
                                        "serviceimage": {
                                            "name": response.file.name,
                                            "description": this.imgdescription,
                                            "path": 'test',
                                        }
                                    }
                                    Drequest.api("serviceimage.attach")
                                        .data(imageToDB)
                                        .raw((respons) => {
                                            if (respons.success) {
                                                this.$fire({
                                                    type: "success",
                                                    text: "Image succeful uploaded..!",
                                                    timer: 2000
                                                })
                                                this.loadingData = false
                                            }
                                            else {
                                                this.$fire({
                                                    type: "error",
                                                    text: "Error occured..!",
                                                    timer: 2000
                                                })
                                            }
                                        })
                                        .catch((err) => {
                                            console.log("err")
                                            this.loadingData = false;
                                        })
                                    this.loadingData = false
                                }
                                else {
                                    this.$fire({
                                        type: "error",
                                        text: "Error occured..!",
                                        timer: 2000
                                    })
                                }
                            })
                            .catch((err) => {
                                console.log("err")
                                this.loadingData = false;
                            })
                    }
                },
                computed: {
                    sortRecipientsItems() {
                        return this.lisOfPostmessage
                    },
                },
                setup() {
                    const icons = { mdiMagnify, mdiCamera, mdiSend, mdiCloseThick }
                    return {
                        icons,
                    }
                },
            }
</script>

            <style lang="scss" scoped>
            .blog-vue-editor {
                width: 100%;
                height: 6em;
            }
        </style>