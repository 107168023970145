<template>
    <v-card flat class="my-2">
        <v-btn class="rounded-lg subprimary" color="primary" text @click="addSkill">Add skill</v-btn>

        <!-- <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text small class="suberror text-capitalize">
                    <span class="text-sm error--text" v-bind="attrs" v-on="on">Activate your account</span>
                </v-btn>
            </template>
            <v-card>

                <v-select v-model="favorites" :items="states" hint="Pick your favorite states" label="Select" multiple
                    persistent-hint></v-select>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog = false">
                        Reset
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <v-row class="d-flex flex-row align-center mt-3 py-0 " v-for="(skil, cle) in userskill" :key="cle">
            <v-col cols="11" class="pt-2">
                <v-text-field v-model="skil.name" :value="skil.name" :label="'Skill - ' + skil.name" dense outlined
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col cols="1">
                <v-btn v-if="skil.showclose === true" elevation="0" small icon class="rounded-lg lightError"
                    @click="deleteskill(cle, skil)">
                    <v-icon small color="error">{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-card-actions class="px-0">
            <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetSkill">
                Unsave
            </v-btn>
            <v-btn color="primary" class="mt-4" :loading="loading ? true : false" @click.prevent="saveSkills">
                Validate update
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    data() {
        return {
            loading: false,
            modeskill: [],
            userskill: [],
            icons: { mdiClose },
            dialog: false,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            favorites: [],
        states: [
          'Alabama', 'Alaska', 'American Samoa', 'Arizona',
          'Arkansas', 'California', 'Colorado', 'Connecticut',
          'Delaware', 'District of Columbia', 'Federated States of Micronesia',
          'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
          'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
          'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
          'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
          'Missouri', 'Montana', 'Nebraska', 'Nevada',
          'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
          'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
          'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
          'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
          'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
          'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
        ],
        }
    },
    methods: {
        loadSkills() {
            Drequest.api(`lazyloading.user-skill?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach(element => {
                        this.userskill.push({ name: element.skill.name.fr, indent: element.skill.id, showclose: true })
                    });
                    this.modeskill = this.userskill
                }
            }).catch((err) => { });
        },

        autoSkillsCompletion() {
            Drequest.api('user-skill.autoskills').get((response) => {
                if (response.success === true) {
                    //     response.listEntity.forEach(element => {
                    //         this.userskill.push({ name: element.skill.name.fr, indent: element.skill.id, showclose: true })
                    // });
                    console.log('response.listEntity')
                    console.log(response.listEntity)
                }
            }).catch((err) => { });
        },
        resetSkill() {
            alert()
            this.userskill.forEach((value, ind) => {
                this.modeskill[ind] = value
            })
        },
        deleteskill(i, value) {
            this.userskill.splice(i, 1)
        },
        addSkill() {
            this.userskill.push({
                name: 'New skill',
                showclose: true,
            })
        },
        saveSkills() {
            this.loading = true;
            var bodyFormData = {
                skills: {
                }
            }
            this.loading = true;
            Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
                .data(bodyFormData)
                .raw((response) => {
                    switch (response.success) {
                        case true:
                            this.loading = false;
                            // this.$router.push({ name: 'user.account' });
                            break;
                        default:
                            alert(response.values.log)
                            this.loading = false;
                            break;
                    }
                }).catch((err) => {
                    this.loading = false;
                });
        }
    },
    computed: {
        vmodelskill() {
            return this.userskill
        },
    },
    beforeMount() {
        this.loadSkills()
    },
    mounted() {
        this.autoSkillsCompletion()
    },
}
</script>