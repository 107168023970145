<template>
  <div elevation="0" class="dash-acc-header">
    <!-- <v-img :src="userbanner ? userbanner : userImg.banner" alt="" class="acc-cover-img"> -->
    <v-img :src="userbanner ? userbanner : ''" alt="" class="acc-cover-img">
      <div class="pa-4">
        <input ref="ref_banner_img" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" :hidden="true"
          @change="onBannerChange" />
        <v-btn class="btn-cover-img" color="secondary" outlined rounded small @click="$refs.ref_banner_img.click()">
          <v-icon size="1.3em">{{ icons.mdiCamera }}</v-icon>
          <span class="ml-1">Modify</span>
        </v-btn>
      </div>
    </v-img>
    <div class="acc-avatar-img">
      <v-avatar size="100%" class="img-avt" @click="$refs.ref_profile_img.click()">
        <!-- <v-img :alt="''" :src="userlogo ? userlogo : this.$store.getters.getuserlogo" cover></v-img> -->
        <v-img :alt="''" :src="userlogo ? userlogo : ''" cover></v-img>
      </v-avatar>
      <input ref="ref_profile_img" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" :hidden="true"
        @change="onProfilChange" />
      <v-btn icon class="img-avt-cam" color="secondary" @click="$refs.ref_profile_img.click()">
        <v-icon>{{ icons.mdiCamera }}</v-icon>
      </v-btn>
    </div>
    <div class="acc-header-subheader">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCheckboxMarkedCircle, mdiCamera } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {

  props: {
    profil: {
      type: Object,
      default: () => { },
    },
    to: {
      type: String,
      default: ''
    }
  },
  data() {

    return {
      isProfessionalSession: true,
      logo: {
        changed: false,
        load: false,
        default: false,
      },
      userImg: {
        logo: '',
        banner: ''
      },
      userlogo: '',
      userbanner: '',

      loader: {
        user: {
          logo: false,
          banner: ''
        },
        upload: {
          logo: false,
          banner: ''
        }
      },
      mcoverfiles: null,
      mprofilfiles: null,
      tem: ''
    }
  },

  methods: {
    getSourceLogo() { },
    getSourceBanner() { },
    onBannerChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createBanner(files[0]);
    },
    onProfilChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.loader.user.logo = false
        return;
      }
      this.loader.user.logo = true
      this.createImage(files[0]);
    },
    createImage(file) {
      var userlogo = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.userlogo = e.target.result;
      };
      reader.readAsDataURL(file);

      this.mprofilfiles = file;

      this.uploadUserlogo()
    },

    createBanner(file) {
      var userbanner = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.userbanner = e.target.result;
      };
      reader.readAsDataURL(file);

      this.mcoverfiles = file;
      this.uploadUserBanner()
    },
    removeBannerImage(e) {
      this.userbanner = '';
    },

    removeProfilImage(e) {
      this.userlogo = '';
    },

    loadlogo(e) {
      let req_logo = `user.loadlogo?user_id=${this.$store.getters.getUser.id}`
      if (this.isProfessionalSession !== true) {
        req_logo = `enterprise.loadlogo?enterprise_id=${this.$store.getters.getCompany.id}`
      }
      Drequest.api(req_logo).get((response) => {
        if (response.success === true) {
          this.userlogo = response.logo
          if (this.isProfessionalSession === true) {
            this.$store.commit("setuserlogo", response.logo);
          }
          else {
            this.$store.commit("setenterpriselogo", response.logo);
          }
        }
        else { }
      }).catch((err) => { });
    },

    loadbanner(e) {
      let req_banner = `user.loadbanner?user_id=${this.$store.getters.getUser.id}`
      if (this.isProfessionalSession !== true) {
        req_banner = `enterprise.loadbanner?enterprise_id=${this.$store.getters.getCompany.id}`
      }
      Drequest.api(req_banner).get((response) => {
        if (response.success === true) {
          this.userbanner = response.banner
          // this.$store.commit("setuserbanner", response.banner);
        }
        else { }
      }).catch((err) => { });
    },
    loadLogoBanner(statut) {
      let req_banner = `user.logobanner?user_id=${this.$store.getters.getUser.id}`
      if (statut !== true) {
        req_banner = `enterprise.logobanner?enterprise_id=${this.$store.getters.getCompany.id}`
      }
      Drequest.api(req_banner).get((response) => {
        if (response.success === true) {
          this.userbanner = response.banner
          this.userlogo = response.logo
          this.loader.user.logo = false
        }
        else {
          this.loader.user.logo = false
        }
      }).catch((err) => {
        this.loader.user.logo = false
      });
    },
    uploadUserlogo() {
      var formdata = new FormData();
      this.loader.user.logo = true
      let req_logo = `user.uploadlogo?user_id=${this.$store.getters.getUser.id}`
      if (this.isProfessionalSession === true) {
        formdata.append("user_form[logo]", this.mprofilfiles);
      }
      else {
        req_logo = `enterprise.uploadlogo?enterprise_id=${this.$store.getters.getCompany.id}`
        formdata.append("enterprise_form[logo]", this.mprofilfiles);
      }
      Drequest.api(req_logo)
        .data(formdata)
        .post((response) => {
          if (this.isProfessionalSession === true) {
            this.$store.commit("setuserlogo", response.logo);
          }
          else {
            this.$store.commit("setenterpriselogo", response.logo);
          }
          this.loadlogo()

        }).catch((err) => {
          this.loader.user.logo = false
        });
    },
    uploadUserBanner() {
      var formdata = new FormData();

      this.loader.user.banner = true
      let req_banner = `user.uploadbanner?user_id=${this.$store.getters.getUser.id}`
      if (this.isProfessionalSession === true) {
        formdata.append("user_form[banner]", this.mcoverfiles);
      }
      else {
        req_banner = `enterprise.uploadbanner?enterprise_id=${this.$store.getters.getCompany.id}`
        formdata.append("enterprise_form[banner]", this.mcoverfiles);
      }
      Drequest.api(req_banner)
        .data(formdata)
        .post((response) => { this.loadbanner() }).catch((err) => {
          this.loader.user.banner = true
        });
    }
  },
  beforeMount() {
    let company = "/dashc"
    if (this.$route.path.substr(0, company.length).toUpperCase() === company.toUpperCase()) {
      this.isProfessionalSession = false
    } else {
      this.isProfessionalSession = true
    }
    this.loadlogo()
    this.loadbanner()
  },
  setup(props) {
    const link = ref(props.to)
    const icons = { mdiCheckboxMarkedCircle, mdiCamera }
    return {
      icons,
      link
    }
  },
}
</script>


<style lang="scss" scoped>
@import '~@/styles/main-dash-style.scss';
@import '~@/styles/utils-style.scss';

.btn-cover-img {
  position: absolute;
  bottom: 1.2em;
  right: 1.2em;
  background: white;
}

.float-profil-camera {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: -webkit-linear-gradient(90deg, var(--v-primary-base) -30%, rgba(72, 149, 236, 0.651) 100%);
}
</style>
