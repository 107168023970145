<template>
    <div>
        <!-- <card-header :header="topheaders"></card-header> -->
            <v-card-text>
                <!-- <h2>Vacancies </h2> -->
                <v-spacer></v-spacer>
                <v-btn text color="secondary rounded-lg" class="subsecondary" @click="feedbackVacancies">
                    <!-- <v-icon class="mr-2">{{ icons.mdiShapeRectanglePlus }}</v-icon> -->
                    <span>Vacancies feedback</span>
                </v-btn>
                <v-btn text color="secondary rounded-lg" class="subsecondary ml-2" @click="addNewVacancies">
                    <v-icon class="mr-2">{{ icons.mdiShapeRectanglePlus }}</v-icon>
                    <span>New Vacancies</span>
                </v-btn>
            </v-card-text>
            <v-card-text>
                <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" class="search-field" hide-details
                    dense outlined clearable>
                </v-text-field>
            </v-card-text>
            <v-data-table :headers="headers" :items="vacancieslist" :search="search" :loading="loaderdata"
                loading-text="Loading vacancies... Please wait" no-data-text="No vacancies available yet" sort-by="object" class="elevation-1 row-pointer"
                style="cursor: pointer" flat>
                <!-- style="cursor: pointer" @click:row="(item) => showCompanyDasboard(item)" flat> -->
                <template v-slot:item.job="{ item }">
                    <v-list three-line>
                        <v-list-item>
                            <v-list-item-avatar size="60" tile class="secondary">
                                <!-- <v-img src="@/assets/images/avatars/profil.svg"></v-img> -->
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <!-- {{item}} -->
                                <v-list-item-title><strong>{{ item.title }}</strong></v-list-item-title>
                                <v-list-item-subtitle>{{ item.company.name }}</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-icon>{{ icons.mdiMapMarker }}</v-icon>
                                    <span>{{ item.location + ',' + item.country.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span class="mr-4"> {{ item.periodicity }}</span>
                                    <span class="error--text"> Deadline: {{ item.dateline }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <!-- <v-list-item-action-text></v-list-item-action-text> -->

                                <v-menu bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon class="subsecondary" v-bind="attrs" v-on="on">
                                            <!-- <v-icon small @click.stop="handleRowClick(item, 'share')" color="secondary"> -->
                                            <v-icon small color="secondary">
                                                {{ icons.mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-text class="pa-2 d-flex flex-column">
                                            <view-vacancies :value="item" :submit="false"></view-vacancies>
                                            <v-btn text small block @click="editvacancies(item)">Edit</v-btn>
                                            <!-- <v-btn text small block @click="sharevacancies">Share</v-btn>
                                            <v-btn text small block @click="deletevacancies">Delete</v-btn> -->
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </template>
            </v-data-table>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMapMarker, mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import ViewVacancies from "@/views/dashboard/vacancies/components/ViewVacancies.vue"
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    components: {
        ViewVacancies,
        CardHeader
    },
    data() {
        return {
            topheaders: {
                titlepage: 'VACANCIES',
                icon: null,
            },
            loading: false,
            loaderdata: true,
            vacancieslist: [],
            // dessert: [],
        }
    },
    beforeMount() {
        this.loaderdata = true
        Drequest.api(`lazyloading.job?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.vacancieslist.push(value)
                    });
                    this.loaderdata = false
                }
                else {
                    this.loaderdata = false
                }
            })
            .catch((err) => {
                // alert('Server error')
                this.loaderdata = false
            });
    },
    methods: {
        handleRowClick(value, srcItem) {
            if (srcItem === 'eye') {
                console.log(this.vacancieslist.indexOf(value))
            }
            else if (srcItem === 'share') {
                console.log(this.vacancieslist.indexOf(value))
            } else {
                console.log(JSON.stringify(value))
            }
        },
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },
        editvacancies(value) {
            this.$router.push({ name: 'company.vacancies.edit', params: {jobid: value.id} })
        },
        deletevacancies() {
            alert('delete')
        },
        sharevacancies() {
            alert('share')
        },
        addNewVacancies() {
            this.$router.push({ name: 'company.vacancies.create', params: {} })
        },
        feedbackVacancies() {
            this.$router.push({ name: 'company.vacancies.feedback', params: {} })
        },
        showCompanyDasboard(item) {
            const companie = this.vacancieslist.find(element => element.id == item.id);
            // this.$store.commit("setCompany", {
            //     id: companie.id,
            //     about: companie.about,
            //     name: companie.company.name,
            //     form: companie.enterprise_form,
            //     type: companie.enterprise_type,
            // });
            // this.$router.push({ name: 'company.home', params: { company: companie.company.name } })
            // this.$router.push({ name: 'company', params: { ids: this.vacancieslist.indexOf(item), id: md5(this.vacancieslist.indexOf(item)) } })
        },
        deleteItem(item) {
            this.editedIndex = this.vacancieslist.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.vacancieslist.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.vacancieslist[this.editedIndex], this.editedItem)
            } else {
                this.vacancieslist.push(this.editedItem)
            }
            this.close()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    setup(props) {
        // button data-table action
        const btnSingle = ref(false)
        const btnRow = ref(false)
        const icons = { mdiMapMarker, mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus }
        let search = ref('')
        const headers = [
            {
                value: 'job',
                align: 'start',
                sortable: true,
            },
        ]
        return {
            // button data-table action
            btnRow, btnSingle,
            icons,
            search,
            headers
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>