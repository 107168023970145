<template>
    <div>
        <v-data-table v-if="stab === 1" :headers="headers" :items="mynetwork" sort-by="user"
            :loading="this.isDataLoading" loading-text="Loading... Please wait" class="elevation-1 row-pointer"
            style="cursor: pointer" @click:row="(item) => viewInvitation(item)">
            <template v-slot:item.content="{ item }">
                <div class="flex justify-between gap-x-6 pt-4 pb-2">
                    <div class="flex min-w-0 gap-x-4 my-auto">
                        <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                            :src="item.isLogo ? urlimg + 'professional/logo/' + item.user.id + '/' + item.user.logo : require('@/assets/images/avatars/profil.jpg')"
                            alt="">
                        <div class="min-w-0 flex-auto">
                            <div class="text-sm font-semibold leading-5 text-gray-900">{{ item.user.firstname }}</div>
                            <div class="mt-1 truncate text-xs leading-6 text-gray-500">
                                {{ item.user.profession + (item.user.namecompany ? ' (' + item.user.namecompany + ')' :
                                    '') }}</div>
                            <p class="mt-1 flex w-auto text-xs leading-6">
                                <v-img v-if="item.user.country.iso"
                                    :src="require('@/assets/images/flags/' + item.user.country.iso + '.svg')"
                                    :lazy-src="'@/assets/images/flags/' + item.user.country.iso + '.svg'"
                                    aspect-ratio="1" width="1.4em" max-width="1.4em" height="0.8em"
                                    class="text-center subsecondary lighten-2 mr-2 my-1">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-skeleton-loader v-bind="attrs" type="image, card-avatar, article"
                                                height="100%" width="100%"
                                                class="mx-auto rounded-pill"></v-skeleton-loader>
                                        </v-row>
                                    </template>
                                </v-img> {{ item.user.country.name }}
                            </p>
                        </div>
                    </div>
                    <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p class="flex text-sm leading-6 text-gray-900 rounded-lg subsecondary px-4">
                            <!-- {{ item.user.country.name }} -->
                        </p>
                        <p class="mt-1 text-xs leading-5 text-gray-500">Last seen <time datetime="2023-01-23T13:23Z">3h
                                ago</time></p>
                    </div>
                </div>
            </template>
        </v-data-table>
        <!-- <v-data-table v-if="stab === 2" :headers="headers" :items="sentinvitation" sort-by="user" :loading="this.isDataLoading"
            loading-text="Loading... Please wait" class="elevation-1 row-pointer" style="cursor: pointer"
            @click:row="(item) => viewInvitation(item)">
            <template v-slot:item.content="{ item }">
                <networking-card :stab="stab" @delete="deleteInvitation($event, item)"
                    @cancel="cancelInvitation($event, item)" @accept="acceptInvitation($event, item)" :join="item">
                </networking-card>
            </template>
        </v-data-table> -->
        <v-data-table v-else :headers="headers" :items="stab === 2 ? sentinvitation : receivedinvitation" sort-by="user"
            :loading="this.isDataLoading" loading-text="Loading... Please wait" class="elevation-1 row-pointer"
            style="cursor: pointer" @click:row="(item) => viewInvitation(item)">
            <template v-slot:item.content="{ item }">
                <invitation-card :stab="stab" @delete="deleteInvitation($event, item)"
                    @cancel="cancelInvitation($event, item)" @accept="acceptInvitation($event, item)" :join="item">
                </invitation-card>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import InvitationCard from './InvitationCard.vue'
import NetworkingCard from './NetworkingCard.vue'

var md5 = require("md5")
export default {
    props: {
        stab: {
            type: Number,
            default: 1
        }
    },
    components: {
        InvitationCard,
        NetworkingCard
    },
    data() {
        return {
            isDataLoading: true,
            joinedData: [],
            mynetwork: [],
            receivedinvitation: [],
            sentinvitation: [],
            editedItem: {},
            editedIndex: -1,

            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        };
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        async viewInvitation(item) {
            // this.$router.push({ 
            //     name: "user.views", params: { f_id: md5(item.id), ids: item.id } 
            // })
        },
        initialize() {
            // created list
        },
        async acceptInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.accept?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation accepted!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        async cancelInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.reject?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation accepted!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        async deleteInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.reject?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation rejected!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.joinedData[this.editedIndex], this.editedItem)
            } else {
                this.joinedData.push(this.editedItem)
            }
            this.close()
        },
    },

    beforeMount() {
        this.isDataLoading = true
        var request = `lazyloading.colaborator?dfilters=on&status:eq=2&user_id=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`
        var isOwner = true

        Drequest.api(request)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.mynetwork.push({
                            id: value.id,
                            user: value.user.id === this.$store.getters.getUser.id ? value.recipientuser : value.user,
                            status: value.status,
                            sended_at: value.created_at,
                            action_at: value.updated_at,
                            isLogo: value.user.id === this.$store.getters.getUser.id ? (value.recipientuser.logo ? true : false) : (value.user.logo ? true : false),
                        })
                    })
                    this.isDataLoading = false
                }
                else {
                    this.isDataLoading = false
                    this.$fire({
                        title: "Error occured!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                console.log("err")
                this.isDataLoading = false
            })

    },
    mounted() {
        Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=1&recipientuser:eq=${this.$store.getters.getUser.id}&user_id:neq=${this.$store.getters.getUser.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.receivedinvitation.push({
                            id: value.id,
                            user: value.user,
                            status: value.status,
                            sended_at: value.created_at,
                            isOwner: false,
                            isLogo: value.recipientuser.logo ? true : false,
                        })
                    })
                    this.isDataLoading = false
                }
                else {
                    this.isDataLoading = false
                    this.$fire({
                        title: "Error occured!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                console.log("err")
                this.isDataLoading = false
            })

        Drequest.api(`lazyloading.colaborator?dfilters=on&status:neq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:neq=${this.$store.getters.getUser.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.sentinvitation.push({
                            id: value.id,
                            user: value.recipientuser,
                            status: value.status,
                            sended_at: value.created_at,
                            isOwner: true,
                            isLogo: value.recipientuser.logo ? true : false,
                        })
                        console.log(value)
                    })
                    this.isDataLoading = false
                }
                else {
                    this.isDataLoading = false
                    this.$fire({
                        title: "Error occured!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                console.log("err")
                this.isDataLoading = false
            })
    },
    setup() {
        const icons = { mdiMagnify, mdiEye, mdiShareAll }
        const headers = [
            { text: '', value: 'content', align: 'start' }
        ]
        const tab = ref('')

        const urlimg = Drequest.__envimg
        return {
            tab, icons, headers, urlimg
        }
    },
}
</script>

<style lang="scss" scoped>
.row-pointer>>>tbody tr :hover {
    // cursor: pointer;
}

.hover-item-user {
    cursor: pointer;
}
</style>