<template>
    <div>
        <v-container v-if="loading.page === false" class="">
            <v-row>
                <v-col cols="12">
                    <v-card class="bg-white mb-4">
                        <div elevation="0" class="dash-acc-header">
                            <v-img :src="userImg.banner" height="150" :lazy-src="userImg.banner" aspect-ratio="1" alt=""
                                class="acc-cover-img" coverclass="subsecondary lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                            class="mx-auto rounded-pill"></v-skeleton-loader>
                                    </v-row>
                                </template>
                                <div class="pa-4">
                                </div>
                            </v-img>
                            <div class="acc-avatar-img">
                                <v-avatar size="100%" class="img-avt">
                                    <v-img :src="userImg.profile" :lazy-src="userImg.profile" aspect-ratio="1" cover
                                        coverclass="subsecondary lighten-2">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-skeleton-loader v-bind="attrs" type="image" height="100%"
                                                    width="100%" class="mx-auto"></v-skeleton-loader>
                                            </v-row>
                                        </template></v-img>
                                </v-avatar>
                            </div>
                        </div>
                        <div class="pa-4">
                            <div class="space-y-5 d-flex flex-row">
                                <p
                                    class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl mr-4">
                                    {{ currentuser.lastname + " " + currentuser.firstname }}
                                </p>
                                <div v-if="currentuser.country">
                                    <v-img :src="require('@/assets/images/flags/' + currentuser.country.iso + '.svg')"
                                        :lazy-src="'@/assets/images/flags/' + currentuser.country.iso + '.svg'"
                                        aspect-ratio="1" width="1.6em" height="1em" size="24"
                                        class="text-center subsecondary lighten-2">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-skeleton-loader v-bind="attrs" type="image" height="100%"
                                                    width="100%" class="mx-auto rounded-pill"></v-skeleton-loader>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>

                            </div>
                            <div v-if="currentuser.country"
                                class="d-flex flex-row justify-content-center align-items-center">
                                <p class="max-w-3xl mt-5 text-md text-gray-500">
                                    <v-icon size="1em" color="primary">
                                        {{ icons.mdiMapMarkerOutline }}
                                    </v-icon>
                                    {{ currentuser.country.name }}
                                </p>
                            </div>

                            <div class="d-flex align-left align-item-center">
                                <div class="d-flex flex-row rouned-md min-h-16 rounded-md px-4 py-2">
                                    <span
                                        class="rounded-full  -ml-6 z-20 bg-purple-500 h-12 w-12  flex justify-center items-center border"></span>
                                    <span
                                        class="rounded-full  -ml-6 z-20 bg-blue-500  h-12 w-12 flex justify-center items-center border"></span>
                                    <span
                                        class="rounded-full  -ml-6 z-20 bg-yellow-500  h-12 w-12 flex justify-center items-center border"></span>
                                    <span
                                        class="rounded-full  -ml-6 z-20 bg-indigo-500  h-12 w-12 flex justify-center items-center border"></span>
                                    <span
                                        class="rounded-full text-white -ml-6 z-20 black h-12  sm:px-2 px-2 py-0 flex justify-center items-center border">
                                        {{ countfriend }} relation(s)
                                    </span>
                                    <!-- {{ this.$store.getters.isLoggedIn }} -->
                                    <v-btn class="rounded-xl my-auto ml-4" color="secondary" @click="joinToConnect()">
                                        {{ this.$store.getters.isLoggedIn === true?(isOnColaboration === true ? 'Connected' : 'Invite'):'Join to connect' }}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card>

                    <div class="my-8">
                        <div class="bg-white shadow rounded-lg pa-4">
                            <h2 class="text-xl font-bold mb-4">About Me</h2>
                            <p class="text-gray-700">{{ currentuser.about }}
                            </p>

                            <h2 class="text-xl font-bold mt-6 mb-4">Experience</h2>
                            <div class="mb-6">
                                <div class="flex justify-between flex-wrap gap-2 w-full">
                                    <span class="text-gray-700 font-bold"></span>
                                    <p>
                                        <span class="text-gray-700 mr-2"></span>
                                        <span class="text-gray-700"></span>
                                    </p>
                                </div>
                                <p class="mt-2">

                                </p>
                            </div>
                        </div>
                    </div>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-4">
                            Networking
                        </div>
                        <v-divider claa></v-divider>
                        <v-row class="pa-4 ">
                            <v-col col="12" md="3" v-for="(item, index) in colaborators" :key="index">
                                <user-sm :user="item" class=""></user-sm>
                            </v-col>
                        </v-row>
                        <div class="pa-4">
                            <v-btn color="primary" class="mx-auto">Show more</v-btn>
                        </div>
                    </v-card>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-3">
                        </div>
                        <v-card-text class="">
                        </v-card-text>
                    </v-card>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-3">
                        </div>
                        <v-card-text class="">
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="ms-auto" v-else>
            <v-row>
                <v-col col="12" md="12">
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
                    <div>
                        <v-skeleton-loader v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" md="3" v-for="i in 4" :key="i">
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-two-line, actions">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import UserCard from '@/views/pages/users/components/UserCard.vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiHelpCircleOutline, mdiAccount, mdiMapMarkerOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import UserSm from '@/views/pages/users/components/UserSm.vue'

export default {
    components: {
        UserCard,
        UserSm
    },
    data() {
        return {
            colab: {},
            loading: {
                page: true,
                coverimage: false,
                connection: false,
            },
            userImg: {
                profile: '',
                banner: ''
            },
            currentuser: {},
            userList: [],
            colaborators: [],
            idis: this.$route.params.ids,
            countfriend: 0,
            isOnColaboration: false,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        }
    },

    methods: {
        loadImage() {
            this.loading.coverimage = true;
        },

        loadLogoBanner: function (e) {
            Drequest.api(`user.logobanner?user_id=${this.idis}`).get((response) => {
                if (response.success === true) {
                    this.userImg.profile = response.logo
                    this.userImg.banner = response.banner
                }
                else { }
            }).catch((err) => { });
        },

        cancelInvitation(item) { },
        onReadyAndSendNewColaboration() {
            var formColaborator = {
                "owner": this.$store.getters.getUser.id,
                "recipient": this.$store.getters.getColaboration.recipient,
            }
            Drequest.api("colaboration.send")
                .toFormdata(formColaborator)
                .post((response) => {
                    switch (response.success) {
                        case true:
                            this.$fire({
                                type: response.etablished === true ? "error" : "success",
                                text: response.detail,
                                timer: 3000
                            })
                            break;
                        default:
                            this.$fire({
                                type: "Error",
                                text: response.detail,
                                timer: 3000
                            })
                            this.loading = false
                            break;
                    }
                })
                .catch((err) => {
                    this.$fire({
                        type: "error",
                        text: "Connexion error..!"
                    })
                    this.loading = false;
                });
        },
        joinToConnect() {

            if (!this.$store.getters.isLoggedIn) {

                this.$store.commit("setColaboration", {
                    initialized: true,
                    owner: '',
                    name: this.currentuser.firstname,
                    recipient: this.$route.params.ids,
                });
                // this.$fire({
                //     text: "You have to logIn..!"
                // })
                this.$router.push({ name: 'login' })
            }
            else {
                if (this.isOnColaboration === false) {
                    this.onReadyAndSendNewColaboration()
                }
            }
        },
        checkColaboration() {
            if (this.$store.getters.isLoggedIn) {
                Drequest.api(`colaboration.check?owner=${this.$store.getters.getUser.id}&recipient=${this.$route.params.ids}`)
                    .get((response) => {
                        if (response.success === true) {
                            this.isOnColaboration = response.etablished
                        }
                    })
                    .catch((err) => {
                        this.loading.page = false;
                    })
            }
        }
    },
    mounted() {
        this.loading.connection = true;
        Drequest.api(`lazyloading.colaborator?dfilters=on&status=2&user_id=${this.idis}&next=1&per_page=4`)
            .get((response) => {
                if (response.success === true) {
                    this.countfriend = response.nb_element
                    response.listEntity.forEach((value, i) => {
                        this.colaborators.push(value.user)
                    })
                    this.loading.connection = false;
                }
                else {
                    console.log("")
                    this.loading.connection = false;
                }
            })
            .catch((err) => {
                this.$fire({
                    type: "error",
                    text: "Connexion error..!"
                })
                this.loading.connection = false;
            });
    },
    beforeMount() {
        const currentId = this.$route.params.ids
        this.checkColaboration()
        this.loading.page = true

        this.loadLogoBanner()

        var lazyRequest = "lazyloading.user"
        if (this.$store.getters.isLoggedIn) {
            lazyRequest = `lazyloading.user?dfilters=on&id:neq=${this.$route.params.ids}&next=1&per_page=4`
        }
        // Drequest.api(lazyRequest)
        //     .get((response) => {
        //         switch (response.success) {
        //             case true:
        //                 response.listEntity.forEach((value, index) => {
        //                     // const resData = 
        //                     this.userList.push({
        //                         id: value.id,
        //                         email: value.email,
        //                         firstname: value.firstname,
        //                         lastname: value.lastname,
        //                         phonenumber: value.phonenumber,
        //                         username: value.username,
        //                         adress: value.adress,
        //                         city: value.city,
        //                         lastlogin: value.last_login,
        //                         logo: value.logo
        //                     })
        //                 })
        //                 this.loading.page = false
        //                 break;
        //             default:
        //                 this.$fire({
        //                     type: "error",
        //                     text: "Veuillez recharger la page..!",
        //                     timer: 2000
        //                 })
        //                 break;
        //         }
        //     })
        //     .catch((err) => {
        //         this.loading.page = false;
        //     })

        Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$route.params.ids}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.currentuser = value
                        // this.currentuser = {
                        //     id: value.id,
                        //     email: value.email,
                        //     firstname: value.firstname,
                        //     lastname: value.lastname,
                        //     phonenumber: value.phonenumber,
                        //     username: value.username,
                        //     adress: value.adress,
                        //     city: value.city,
                        //     lastlogin: value.last_login,
                        //     logo: value.logo
                        // }
                    })
                    this.loading.page = false
                }
            })
            .catch((err) => {
                this.loading.page = false
            })
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiHelpCircleOutline,
            mdiAccount,
            mdiMapMarkerOutline
        }

        return {
            icons
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';

.avatar-center {
    top: -2rem;
    left: 1rem;
    position: absolute;
}

.user-profile-intro {
    background: var(--v-secondaryAccent-base);
    padding: 1em 0px;
    border-radius: 0.5em;

    .user-profile-intro-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        paddin .item-icon,
        .item-keyword,
        .item-value {
            margin-right: 0.4em;
            color: var(--v-accent-base);
            opacity: 0.8;
        }

        .item-icon {
            margin-right: 0.1em;
            width: 0.7em;
        }

        .item-keyword {
            font-weight: normal;
            font-size: 12px !important;
        }

        .item-value {
            font-weight: bold;
            font-size: 13px !important;
        }
    }
}
</style>
