<template>
    <div v-if="tabs">
        <ul class="header-tabs-large">
            <li v-for="(tab, index) in tabs" :key="index" @click="toggleTabs(index)" class="header-tabs-item">
                <a href="#" :class="openTab === index ? 'header-tabs-item-active' : ''" class='header-tabs-item-btn'>
                    {{ tab }}
                </a>
            </li>
        </ul>
    </div>

</template>


<script>

import {
    mdiBriefcase, mdiOpenInNew, mdiShareAll, mdiEye
} from '@mdi/js'
import { type } from 'jquery';
export default {
    props: {
        tabs: {
            type: Array(),
            default: () => ['Tab 1', 'Tab2', 'Tab 3']
        },
        defaulttab: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            openTab: this.defaulttab - 1,
        }
    },
    methods: {
        toggleTabs(tabNumber) {
            this.openTab = tabNumber
            this.$emit('tab', tabNumber + 1);
        },
    },
    setup(props) {
        const icons = {}

        return {
            icons
        }
    }
}
</script>
<style lang="scss">
    
.header-tabs-large {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: solid 1px var(--v-secondary-base) !important;
  margin: 0.7em 0px 1.5em 0px;

  .header-tabs-item {
    // flex: 3;
    min-width: 8em;
    text-align: center;
    margin-bottom: -1px;

    .header-tabs-item-btn {
      width: 100%;
      height: 100%;
      background: none;
      display: inline-block;
      padding: 0.6em 1em;
      font-weight: bold;
      font-size: 11pt;
      color: var(--v-secondary-base) !important;
      text-emphasis-color: var(--v-secondary-base) !important;
    }

    .header-tabs-item-active {
      // 'border-l border-t border-r rounded-t text-blue-700'
      border-left: solid 1px var(--v-secondary-base);
      border-right: solid 1px var(--v-secondary-base);
      border-top: solid 1px var(--v-secondary-base);
      border-top-left-radius: 0.2em;
      border-top-right-radius: 0.2em;
      background: var(--v-subsecondary-base);
    }
  }
}
</style>
